import { Trans, useTranslation } from "react-i18next";
import { useRecoilState } from "recoil"
import { ShowHideText } from "../../Atoms/ShowHideText"
import {LinkText} from './LinkText';

export default function Consent() {
    const [showHideText, setShowHideText] = useRecoilState(ShowHideText)
    const { t, i18n } = useTranslation();

    // const linkName = showHideText ? t("ReadLess") : t("ReadMore")
    // let showMore = <a className="read-more-link" onClick={()=>{setShowHideText(!showHideText)}}>
    //     <p style={{textDecoration: 'underline'}}>{linkName}</p>
    // </a>

    // var medlemsLink = <a href="https://www.georgjensen.com/da-dk/medlemsbetingelser
    // ">medlemsbetingelserne</a>;
    // var personDataLink = <a href="https://www.georgjensen.com/da-dk/persondatapolitik
    // ">persondatapolitikken</a>;

    return (
        <div id="disclaimer">
             {/* <Trans
                i18nKey="Consent1"
            /> */}

            
                <Trans
                    i18nKey="Consent_main"
                    components={{
                        link1: <LinkText to={t("PersonDataLink")} title="persondatapolitikken" />,
                    }}
                />
            
            <div style={{paddingTop: 10}}>
                <Trans
                    i18nKey="Consent_sub"
                    components={{
                        link1: <LinkText to={t("MedlemsLink")} title="medlemsbetingelserne" />,
                        link2: <LinkText to={t("PersonDataLink")} title="persondatapolitikken" />
                    }}
                />
            </div>

            {/* <p>
                Ja, jeg vil gerne modtage tilpasset markedsføring fra Georg Jensen vedrørende produkter, tilbud, events, konkurrencer, invitationer, nyheder mv. pr. e-mail eller sms,
                 afhængigt af de kontaktoplysninger jeg har givet til Georg Jensen. Jeg kan altid tilbagekalde mit samtykke via et afmeldingslink i henvendelsen eller på min profil. 
            </p>
            { showHideText &&
            <p>
                Georg Jensen vil indsamle og behandle mine kontaktoplysninger sammen med min købsinformation og adfærdsinformation indsamlet via brug af cookies og lignende teknologier, såsom pixels, tags og andre identifikatorer. Ved at trykke på 'Opret konto' accepterer jeg samtidig {medlemsLink} og {personDataLink}.*
            </p>}
            {showMore}  */}
        </div>
    )
}