import { useTranslation } from "react-i18next";
import generalStyles from './DefaultComplete.module.scss';

import QRCode from "react-qr-code";
import { useRecoilValue } from "recoil";
import { QrcodeAtom } from "../../../Atoms/QrcodeAtom";
import { useEffect } from "react";

export default function DefaultComplete() {
    const qrcode = useRecoilValue(QrcodeAtom);
    const { t } = useTranslation();

    useEffect(() => { 
        goToTop();

      return () => {
        
      }
    }, [] )

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (

        <div  id='welcomeText' className={generalStyles.center}>
            {
                qrcode.IsExistingMember ? <p id='welcomeText'>{t("WelcomeBack") as string} {qrcode.Fullname}</p> : <p id='welcomeText'>{t("Welcome") as string} {qrcode.Fullname}</p>
            }
            {
                qrcode.IsExistingMember ? <QRCode size={192} level="H" bgColor='#ACE3FF' value={qrcode.Email} /> : <QRCode size={192} level="H" value={qrcode.Email} />
            }
                
            <div id='completeDisclaimer' >
                <p>({qrcode.Email})</p>

                {
                    qrcode.IsExistingMember 
                    ? 
                    <div></div>
                    :
                    <p style={{marginTop: '30px'}}>
                        {t("EmailConfirmation") as string}
                    </p>
                }
                
            </div>
        </div>

    )
}