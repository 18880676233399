import { useTranslation } from "react-i18next";
import generalStyles from './AuComplete.module.scss';
import QRCode from "react-qr-code";
import { useRecoilState, useRecoilValue } from "recoil";
import { QrcodeAtom } from "../../../../Atoms/QrcodeAtom";
import { useEffect } from "react";
import { EmailSize } from "../../../../Atoms/EmailSize";

export default function AuComplete() {
    const qrcode = useRecoilValue(QrcodeAtom);
    const [fontSize, setFontSize] = useRecoilState(EmailSize)
    const { t } = useTranslation();

    

    useEffect(() => { 
        goToTop();
        setFontSize(calcFontSize(qrcode.Email));


      return () => {
        
      }
    }, [] )

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (

        <div>
            <div  id='welcomeText' className={generalStyles.center}>
                {
                    qrcode.IsExistingMember ? <p id='welcomeText'>{t("WelcomeBack") as string} {qrcode.Fullname}</p> : <p id='welcomeText'>{t("Welcome") as string} {qrcode.Fullname}</p>
                }
                {
                    qrcode.IsExistingMember ? <QRCode size={192} level="H" bgColor='#ACE3FF' value={qrcode.Email} /> : <QRCode size={192} level="H" value={qrcode.Email} />
                }
            </div> 

            <div id='completeDisclaimer'>
                <p style={{fontSize: `${fontSize}px`}}>({qrcode.Email})</p>
                {
                    qrcode.IsExistingMember 
                    ? 
                    <div></div>
                    :
                    <p style={{marginTop: '30px'}}>
                        {t("EmailConfirmation") as string}
                    </p>
                }
            </div>
        </div>
    )
}

function calcFontSize(email: string): number{

    if (email.length > 50)
    {
        return 10;
    } else if (email.length < 50 && email.length > 40) {
        return 15;
    } else if (email.length < 40 && email.length > 30) {
        return 20;
    } else if (email.length < 30 && email.length > 20) {
        return 22.5;
    } else if (email.length < 20) {
        return 25;
    } else {
        return 5;
    }

}