import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import GB from "./assets/translation/en.json";
import DK from "./assets/translation/da.json";
import SE from "./assets/translation/sv.json";
import JP from "./assets/translation/jp.json";

const resources = {
    GB,
    DK,
    SE,
    JP,
}

i18next
.use(initReactI18next)
.init({
  resources,
  defaultNS: "Common",
  fallbackLng: "GB"
});

export default i18next;