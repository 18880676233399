import './App.module.scss';
// import "antd/dist/antd.css"
import "./Theme/antd-customized.css";
import Landing from './Pages/Landing/Landing';
import { BrowserRouter } from 'react-router-dom';

function App() {

  return (
    <BrowserRouter>
      <Landing />
    </BrowserRouter>
  );
}

export default App;
